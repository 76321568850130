import { render, staticRenderFns } from "./IOUHistory.vue?vue&type=template&id=4446ec33&scoped=true&"
import script from "./IOUHistory.vue?vue&type=script&lang=ts&"
export * from "./IOUHistory.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4446ec33",
  null
  
)

export default component.exports